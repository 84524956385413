import ContactContainer from '../containers/Contact'

const Contact = () => {

  return (
    <>
      <ContactContainer />
    </>
  );
}

export default Contact;

