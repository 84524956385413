import Header from "../layouts/Header";
import WelcomeArea from '../components/WelcomeArea';
import SecParttns from '../components/SecParttns';
import CreativeSolutions from '../components/CreativeSolutions';
import OurService from '../components/OurService';
import SpreadMap from '../components/SpreadMap';
import OurFeatures from '../components/OurFeatures';
import WhatIsNext from '../components/WhatIsNext';
import FooterPages from '../layouts/Footer/FooterPages';

const HomePage = () => {
    return (
      <div>
        <Header
          ClassNav="navbar navbar-expand-lg navbar-white fixed-top"
          Title="ProVPN - Secure Your Internet Freedom"
        />
        <WelcomeArea
          ClassSec="hero-section ai5 relative"
          welcomeContClass="welcome-content demo1 text-left"
          specialHeadClass="special-head"
          text="The Ultimate VPN Experience"
          ClassTitle="bold b-text"
          title="Get 7 Days of Free VPN Access"
          dummyText="Experience the fastest and most secure VPN service with ProVPN. Protect your privacy, unblock content, and enjoy unlimited bandwidth on both iOS and Android devices."
          addRow={true}
        />
        <div className="clearfix" />
        <SecParttns
            ClassUp="container parttns parttns-mr"
        />
        <CreativeSolutions
            SecClass="blue-bg special-mr section-padding-100"
            topTitle="Why Choose ProVPN?"
            downTitle="Fast, Secure, and Reliable"
            topText="ProVPN is designed to keep your online activity private and secure. With our cutting-edge encryption and global servers, you can browse freely and securely from anywhere in the world."
            downText="Whether you're streaming, gaming, or just browsing, ProVPN ensures you have the fastest and most reliable connection at all times."
        />
        <OurService
            ClassSec="our_services_area section-padding-100-0"
            titleTop="ProVPN Features"
            titleDown="What We Offer"
            text="Enjoy unrestricted access to the internet with ProVPN. Our service includes unlimited bandwidth, high-speed servers, and top-tier security features to ensure your data remains private."
        />
        <SpreadMap
            ClassSec="spread-map section-padding-100"
            titleTop="Global Server Network"
            titleDown="Connect Anywhere, Anytime"
            textTop="ProVPN offers a vast network of servers across the globe, allowing you to connect from virtually any location. Our global coverage ensures that you always have a fast and stable connection."
            textDown="Join millions of users who trust ProVPN for their online security and privacy."
        />
        <OurFeatures />
        <div className="clearfix" />
        <WhatIsNext
          titleTop="Ready to Secure Your Internet?"
          titleDown="Start Your Free 7-Day Trial Now!"
          text="Download ProVPN"
        />
        <FooterPages
          mt0={true}
          text="ProVPN is committed to providing the best VPN service available. Try our 7-day free trial today and experience the difference. Your privacy is our priority."
        />
      </div>
    );
};

export default HomePage;
