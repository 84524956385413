import { useEffect } from 'react';
import { NavLink } from "react-router-dom";

import Logo from '../../data/data-layout/Header/data-Header.js';
import dataNav from '../../data/data-layout/Header/data-Nav.json';
import dataNavContact from '../../data/data-layout/Header/data-nav-contact.json';

import { Addshrink, moveSmooth } from "../../utils/";
import './header.css';
import Preloader from '../../components/Preloader';

const Header = ({ Title, ClassNav = "", navMode = "home" }) => {

  useEffect(() => {
    Addshrink();
  }, []);

  useEffect(() => {
    moveSmooth();
  }, []);

  const navLinks = navMode === "home" ? dataNav : dataNavContact;

  return (
    <>
      <Preloader Title={Title} />
      <nav className={ClassNav} id="banner">
        <div className="container">
          <a className="navbar-brand" href="/"><span><img src={Logo} alt="logo" /></span></a>
          <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav">
              <li className="nav-item">
              {
                navMode === "home" ?
                <a className="nav-link" href="#home">Home</a> :
                <NavLink className="nav-link" to="/">Home</NavLink>
              }
              </li>
              {navLinks && navLinks.map((item, key) => (
                <li className="nav-item" key={key}>
                  {item.path.startsWith('#') ? (
                    <a className="nav-link" href={item.path}>{item.nameLink}</a>
                  ) : (
                    <NavLink className="nav-link" to={item.path}>{item.nameLink}</NavLink>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
