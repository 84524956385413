import './Contact.scss'

import Header from '../../layouts/Header'

import SecForm from "./SecForm"

import FooterPages from '../../layouts/Footer/FooterPages'

const ContactContainer = () => {

  return (
    <>
		<Header
			ClassNav="navbar navbar-expand-lg navbar-white fixed-top"
			Title="Contact"
			navMode='contact'
		/>
		<SecForm />
		<FooterPages
			mt0={true}
			pt100={true}
			text='ProVPN is committed to providing the best VPN service available. Try our 7-day free trial today and experience the difference. Your privacy is our priority.'
		/>
    </>
  );
}

export default ContactContainer