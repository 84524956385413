import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import SectionHeading from '../../../components/SectionHeading';
import data from "../../../data/data-containers/data-contact.json";
import ServicesBlockInfo from "../../../data/data-containers/data-contact.js";

const SecForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage('Sending...');

    emailjs.send('service_4rzo1yp', 'template_mfib2vj', formData, 'F3prcwlwgGaTimbUJ')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setMessage('Message sent successfully!');
      }, (err) => {
        console.log('FAILED...', err);
        setMessage('Failed to send message. Please try again later.');
      });
  };

  return (
    <section className="section-padding-100 features" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionHeading
              title="Contact us"
              textTop="Contact With Us"
              textDown="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis accumsan nisi Ut ut felis congue nisl hendrerit commodo."
              ClassTextDIV="top-sec-h"
              ClassTextH2=""
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="services-column col-lg-5 col-md-12 col-xs-12">
            {ServicesBlockInfo && ServicesBlockInfo.map((item, key) => (
              <div className="services-block-four" key={key} style={{ marginBottom: item.addMB ? 0 : "" }}>
                <div className="inner-box">
                  <div className="icon-img-box con">
                    <img src={item.img} alt="" />
                  </div>
                  <h3><a href="#">{item.title}</a></h3>
                  <div className="text">{item.text}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-12 col-md-12 col-lg-7">
            <div className="contact_form">
              <form onSubmit={handleSubmit} id="main_contact_form" noValidate>
                <div className="row">
                  <div className="col-12">
                    <div id="success_fail_info">{message}</div>
                  </div>
                  {data.map((item, key) => (
                    <div className={item.ClassUp} key={key}>
                      <div className="group wow fadeInUp" data-wow-delay="0.5s">
                        {item.addTextArea ? (
                          <textarea
                            name={item.nameInput}
                            id={item.nameInput}
                            required
                            onChange={handleChange}
                          />
                        ) : (
                          <input
                            type="text"
                            name={item.nameInput}
                            id={item.nameInput}
                            required
                            onChange={handleChange}
                          />
                        )}
                        <span className="highlight" />
                        <span className="bar" />
                        <label>{item.title}</label>
                      </div>
                    </div>
                  ))}
                  <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.6s">
                    <button type="submit" className="btn info-btn">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecForm;
