import PrivacyContainer from '../containers/Privacy';

const Privacy = () => {

  return (
    <>
      <PrivacyContainer />
    </>
  );
}

export default Privacy;

