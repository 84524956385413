import "./Privacy.scss";
import Header from "../../layouts/Header";
import FooterPages from "../../layouts/Footer/FooterPages";

const PrivacyContainer = () => {
  return (
    <>
      <Header
        ClassNav="navbar navbar-expand-lg navbar-white fixed-top"
        Title="Privacy"
        navMode="privacy"
      />
      <section className="section-padding-100 features" id="privacy">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-12 col-sm-12"></div>
            <div className="col-12 col-lg-12 offset-lg-0 col-md-10 offset-md-1">
              <div className="who-we-contant mt-s">
                <h4 className="" data-aos="fade-up">
                  Privacy Policy
                </h4>
                <p className="b-text" data-aos="fade-up">
                  ProVPN Privacy Policy ProVPN is committed to protecting your
                  privacy. We want you to understand what information we
                  collect, what we don't collect, and how we collect, use, and
                  store information. We do not collect logs of your activity,
                  including no logging of browsing history, traffic destination,
                  data content, or DNS queries. We also never store connection
                  logs, meaning no logs of your IP address, your outgoing VPN IP
                  address, connection timestamp, or session duration. Our
                  guiding principle toward data collection is to collect only
                  the minimal data required to operate a world-class VPN service
                  at scale. We designed our systems to not have sensitive data
                  about our customers; even when compelled, we cannot provide
                  data that we do not possess. This privacy policy will help you
                  understand how Tek VPN International Ltd. ("ProVPN," "we,"
                  "our," or "us") collects, uses, and stores information. Learn
                  more about the independent audit report of our privacy
                  protections.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Table of contents
                  <ul>
                    <li>* General Information </li>
                    <li>* Personal Information </li>
                    <li>
                      * Aggregate Apps and VPN Connection Summary Statistics{" "}
                    </li>
                    <li>
                      * Anonymous App Diagnostics, including Crash Reports (can
                      be turned off by the user){" "}
                    </li>
                    <li>* MediaStreamer </li>
                    <li>* Jurisdiction and Applicable Law </li>
                    <li>
                      * Storing of Information Related to Email, Live Chat, and
                      Feedback Forms{" "}
                    </li>
                    <li>* Security Measures to Protect Your Information </li>
                    <li>* Cookies and Mobile Identifiers </li>
                    <li>* Third-Party Websites </li>
                    <li>* Consent and Age Restrictions </li>
                    <li>* Users in the European Union </li>
                    <li>* Changes to the Privacy Policy</li>
                    <li>* How to Contact ProVPN</li>
                  </ul>
                </p>
                <p className="b-text" data-aos="fade-up">
                  General Information ProVPN collects four types of information:
                  (i) Information related to your account ("personal
                  information"). This information is collected for the purpose
                  of administering your ProVPN subscription and includes your
                  name, email address, and payment information, which you submit
                  on our order page when you subscribe for the Services. (ii)
                  Aggregate Apps and VPN connection summary statistics. ProVPN
                  collects minimal information about usage in order to maintain
                  excellent customer support and quality of service. The section
                  below specifies in detail what information we collect. These
                  statistics never include anything about what the user did with
                  the VPN: no data about the contents or destinations of VPN
                  traffic, no DNS queries, and no IP addresses. (iii)
                  (User-controlled option): Anonymous app diagnostics, including
                  crash reports. App diagnostic data, which include crash
                  reports, usability diagnostics, and VPN connection
                  diagnostics, are anonymized and cannot be tied back to
                  individual ProVPN users. This feature is similar to a "send
                  bug report" option. Users can specify in the settings menu of
                  any ProVPN App whether to send these data to us. (iv) Only for
                  users who choose to use the MediaStreamer service: IP
                  addresses authorized to use MediaStreamer. Separately from VPN
                  services, we also provide our optional MediaStreamer service
                  for devices such as Apple TV that do not support VPNs. Users
                  who opt in to using MediaStreamer can choose to register
                  specific IP addresses that should be authorized to use the
                  service; these IP addresses are only used by ProVPN to provide
                  the optional MediaStreamer service and not for any other
                  purpose.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Personal Information ProVPN collects personal information that
                  you provide to us directly through the Site. We require that
                  you provide personal information, such as an email address and
                  payment information, in order to establish a ProVPN account,
                  and so that we can email you, collect payments from you, and
                  respond to support queries that you initiate. The specific
                  information collected varies depending on the payment method
                  you choose. To minimize the amount of personal information you
                  submit to us, we recommend that you use Bitcoin payments when
                  subscribing to our Service. With some forms of payment, the
                  Site may redirect you to the website of a third-party payment
                  processor (namely PayPal, BitPay, or Paymentwall) to
                  transaction. To understand what personal information these
                  processors collect and store, please refer to the respective
                  processor's terms and privacy policy.
                </p>
                <p className="b-text" data-aos="fade-up">
                  ProVPN uses your email address for the following reasons: * To
                  send emails related to payment transactions. * To provide
                  links to our Site, including password reset emails. * To send
                  you updates and announcements. * To communicate with you about
                  your VPN services or respond to your communications. * To send
                  marketing information, such as ProVPN offers, surveys,
                  invitations, and content about other matters in connection
                  with ProVPN ("Marketing emails"). You may choose to not
                  receive Marketing emails by following the opt-out procedure
                  described in these emails. ProVPN uses your personal
                  information only for the purposes listed in this Privacy
                  Policy, and we do not sell your personal information to third
                  parties. The preceding terms do not preclude the transfer of
                  data, in the event that ProVPN is acquired by, or merged with,
                  a third-party entity, to such entity (to be newly created for
                  the purpose of operating the ProVPN business) and its wholly
                  owned subsidiaries/related entities, for legitimate interest,
                  while ensuring at all times the same standards of protection.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Aggregate Apps and VPN Connection Summary Statistics
                </p>
                <p className="b-text" data-aos="fade-up">
                  VPN Connection Summary Statistics: We ensure that we never log
                  browsing history, traffic destination, data content, IP
                  addresses, or DNS queries. Therefore: * We do not know which
                  user ever accessed a particular website or service. * We do
                  not know which user was connected to the VPN at a specific
                  time or which VPN server IP addresses they used. * We do not
                  know the set of original IP addresses of a user's computer.
                  Should anyone try to compel ProVPN to release user information
                  based on any of the above, we cannot supply this information
                  because we do not possess it.
                </p>
                <p className="b-text" data-aos="fade-up">
                  In order to maintain excellent customer support and quality of
                  service, ProVPN collects the following information related to
                  your VPN usage: * Apps and Apps versions: We collect
                  information related to which Apps and Apps version(s) you have
                  activated. Knowing your current version of the Apps allows our
                  Support Team to troubleshoot technical issues with you. *
                  Successful connection: We collect information about whether
                  you have successfully established a VPN connection on a
                  particular day (but not a specific time of the day), to which
                  VPN location (but not your assigned outgoing IP address), and
                  from which country/ISP (but not your source IP address). This
                  minimal information assists us in providing technical support,
                  such as identifying connection problems, providing
                  country-specific advice about how to best use our Service, and
                  to enable ProVPN engineers to identify and fix network issues.
                  * Aggregate sum of data transferred (in MB): We collect
                  information regarding the total sum of data transferred by a
                  given user. Although we provide unlimited data transfer, if we
                  notice that a single user pushes more traffic than thousands
                  of others combined, thereby affecting the quality of service
                  for other ProVPN users, we may contact that user for an
                  explanation.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Summary: We collect minimal usage statistics to maintain our
                  quality of service. We may know, for example, that our
                  customer John had connected to our New York VPN location on
                  Tuesday and had transferred an aggregate of 823 MB of data
                  across a 24-hour period. John can't be uniquely identified as
                  responsible for any specific behavior because his usage
                  pattern overlaps with thousands of other ProVPN customers who
                  also connected to the same location on the same day. We've
                  engineered our systems to categorically eliminate storage of
                  sensitive data. We may know THAT a customer has used ProVPN,
                  but we never know HOW they have utilized our Service. We stand
                  by our firm commitment to our customers' privacy by not
                  possessing any data related to a user's online activities.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Anonymous App Diagnostics, including Crash Reports (can be
                  turned off by the user): With your permission, we collect
                  anonymized app diagnostic data, which include crash reports,
                  usability diagnostics, and VPN connection diagnostics. We use
                  these data in our network operations tools to help optimize
                  network speeds and to let us identify problems and areas for
                  improvement related to specific apps, VPN servers, or ISPs.
                  The information we receive is fully anonymized and cannot be
                  tied back to individual ProVPN users (i.e., we do not store
                  which user sent which data, and we do not store user IP
                  addresses). If you opt in to share this information with
                  ProVPN, we will collect the following anonymized information:
                  * Diagnostic information about if and how a VPN connection
                  attempt failed. * Speed test data. * App diagnostics,
                  including crash reports and usability diagnostics, also
                  without any personally identifiable information. These are
                  handled in an anonymized form by these third parties,
                  dependent on the platform you are using ProVPN on: Windows:
                  Hockeyapp, owned by Microsoft, and Sentry, owned by Functional
                  Software, Inc. See Microsoft's Privacy Policy and Sentry's
                  Privacy Policy. Mac: Crashlytics, owned by Google, and Sentry,
                  owned by Functional Software, Inc. See Crashlytics's Privacy
                  Policy and Sentry's Privacy Policy. Linux: Sentry, owned by
                  Functional Software, Inc. See Sentry's Privacy Policy. iOS:
                  Firebase Crashlytics, owned by Google, and Apple. See Apple's
                  Privacy Policy and Firebase's Privacy and Security
                  documentation. You can disable Apple's crash reporting in iOS
                  settings as described here. Android: Firebase Crashlytics,
                  owned by Google. See Firebase's Privacy and Security
                  documentation. Browser extensions: Google Analytics, owned by
                  Google. See Google's Privacy Policy. Upon activation of any
                  ProVPN App, you will be asked if you would like to share these
                  data. You can start or stop sharing these diagnostic data at
                  any time in the App's settings menu. On iOS, Apple's crash
                  reporting can be turned off in iOS settings.
                </p>
                <p className="b-text" data-aos="fade-up">
                  MediaStreamer: MediaStreamer is our service for consoles like
                  Apple TV and other devices that don't support running a VPN.
                  Because the service doesn't run on an app and doesn't have an
                  option for username/password authorization, we rely on a
                  system that authorizes specific IP addresses that you have
                  chosen to register with us. You can register IP addresses by
                  logging in to our website and using the "DNS Settings" page.
                  Those IP addresses are then stored in our system in order to
                  identify authorized devices for MediaStreamer, and are not
                  used for any other purpose. If you do not wish to use this
                  service but have devices like an Apple TV that cannot run a
                  VPN, we suggest using the ProVPN App for routers. Like all of
                  our Apps and VPN Service, the App for routers does not require
                  IP address registration. Please contact us, and we'll guide
                  you through the steps.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Jurisdiction and Applicable Law: ProVPN's core mission is to
                  keep your information private. In service of this mission,
                  ProVPN's headquarters and registered place of business is in
                  the British Virgin Islands (BVI), which has stricter laws
                  concerning information disclosure than most countries. The BVI
                  has no data retention laws, and any legal order requiring a
                  BVI company to disclose customer records must come from the
                  BVI Supreme Court. Under BVI law, information requests from
                  foreign courts or law enforcement are subject to a "dual
                  criminality" provision, meaning that the request is upheld by
                  the BVI Supreme Court only if the same crime is punishable by
                  at least a one-year prison sentence under BVI law, had it
                  taken place in the BVI. Should we receive a valid legal order
                  from the BVI Supreme Court, it is important to note that
                  ProVPN does not collect any IP addresses, browsing history,
                  traffic data, or DNS queries that could be used to identify
                  any specific user.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Storing of Information Related to Email, Live Chat, and
                  Feedback Forms: ProVPN keeps records of any correspondence,
                  questions, complaints, or compliments you submit to us through
                  our Site or Services, along with our response. Depending on
                  how you contact ProVPN, we may collect your email address and
                  any additional information you provide to us. Having full
                  correspondence records enables our staff to provide the best
                  possible customer support experience. We use two different
                  third-party platforms for support correspondence: Zendesk for
                  emails and support tickets, and SnapEngage for live chat. When
                  you correspond with us, they will store your correspondence
                  records —including your email address, as well as user and
                  device attributes that help with troubleshooting, such as the
                  country you are contacting us from and your device's operating
                  system. Both platforms utilize modern security practices and
                  HTTPS encryption.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Security Measures to Protect Your Information: ProVPN uses
                  best-in-class physical, procedural, and technical security
                  with respect to our offices and information storage facilities
                  so as to prevent any loss, misuse, unauthorized access,
                  disclosure, or modification of information. Access to user
                  information is restricted to staff who require such access to
                  perform their job functions. While we believe these systems
                  are robust, it is important to understand that no data
                  security measures in the world can offer 100% protection.
                  Servers are housed in data centers with strong security
                  practices. None of these data centers require us to collect or
                  store any traffic data or personal information related to your
                  use of VPN Services. If any data center were to ask us to log
                  such data, we would immediately cease operations with said
                  data center and find alternative options. Even if a government
                  were to physically seize one of our VPN servers and manage to
                  break its disk encryption, there would be no logs or
                  information that would tie any individual user to a particular
                  event, website, or behavior.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Cookies and Mobile Identifiers: What is a cookie? A cookie is
                  a small text file used to store information about your visit
                  to the Site. Cookies let ProVPN optimize and improve the user
                  experience of the Site by helping us deliver certain
                  functionalities, such as website login and language settings.
                  The cookies we use may vary over time as we continuously
                  update and improve our Site.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Disabling cookies: You are free to change your cookie
                  preferences at any time. You can do this in the settings panel
                  for your browser. Depending on which browser and device you
                  use, you may be able to control which cookies you allow, which
                  cookies you want to block in the future, and delete cookies.
                  For more information about these settings, please refer to the
                  "help" section of your browser. Note that ProVPN's Site may
                  not work as intended if you choose to disable cookies.
                </p>
                <p className="b-text" data-aos="fade-up">
                  ProVPN's cookies: The cookies set by ProVPN enable us to set
                  your language preference, attribute visitors to a marketing
                  channel, and, once you log in, securely show you information
                  that is specific to your account. The cookies contain a user
                  identifier, but no directly personally identifying information
                  such as your name or email address, and do not track any
                  activity outside of ProVPN's domains.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Third-party cookies: ProVPN uses third-party services such as
                  Google Analytics and Adwords. Cookies from such services are
                  used to collect data for statistical reports. For example, we
                  may generate reports regarding the amount of time users spend
                  on the Site and the number of users who visit the site. ProVPN
                  uses Google AdWords remarketing to show advertisements on
                  third-party websites (including Google) to users who have
                  visited our Site. We may show such users advertisements on a
                  Google search results page, or on a site in the Google Display
                  Network. Third-party vendors, including Google, use cookies to
                  serve ads based on someone's past visits to the Site. Any data
                  collected will be used in accordance with our Privacy Policy
                  and Google's privacy policy. Users may opt out of Google's use
                  of cookies by visiting the Google Advertising Opt-out Page.
                  Users may opt out of Google Analytics by visiting the Google
                  Analytics Opt-out Page. Users may opt out of third-party
                  vendor use of cookies by visiting the Network Advertising
                  Initiative Opt-out Page.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Mobile identifiers: A mobile identifier is an identifier
                  provided by an Android or iOS device. It does not contain your
                  name or email address. ProVPN uses mobile identifiers to
                  generate statistics related to the marketing channels and
                  advertising partners through which users learned about and
                  signed up for ProVPN mobile apps.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Disabling or resetting mobile identifiers: Users may disable
                  or reset the mobile identifiers associated with their devices
                  at any time. For instructions, see Apple's page on Advertising
                  & Privacy on iOS devices and Google's page on Managing your
                  Google Settings on your Android device.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Third-Party Websites: The Site may contain links to external
                  websites that do not fall under ProVPN's domain. ProVPN is not
                  responsible for the privacy practices or content of such
                  external websites.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Consent and Age Restrictions: By using the Site, Content,
                  Apps, Software, or Services, you agree to have your
                  information handled as described in our Terms of Service and
                  Privacy Policy. The Services are intended for adults aged 18
                  and above. If you believe your child has provided information
                  to us, please let us know immediately.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Users in the European Union: ProVPN is committed to user
                  privacy globally, and our existing practices reflect that
                  through minimal collection of data and ensuring users have
                  control over their personal information. The General Data
                  Protection Regulation (GDPR) of the European Union (EU)
                  requires us to outline those practices in a specific manner
                  for users in the EU. In line with the GDPR, we collect and
                  process the data outlined in this Privacy Policy on one of the
                  following bases, depending on the circumstances: * For the
                  purposes of fulfilling our contractual obligations to users ,
                  including: Providing users with the Services and Apps they
                  have requested. Managing user subscriptions and processing
                  payments. Providing customer support. * For a legitimate
                  interest associated with the operation of our business,
                  including: Enhancing the quality, reliability, and
                  effectiveness of our Site, Services, and Apps. Communicating
                  with customers to provide information and seek feedback
                  related to our Services and Apps. * With the consent of users,
                  which users can withdraw at any time. You can exercise your
                  rights under the GDPR to access, transfer, correct, delete, or
                  object to the processing of your personal information by
                  contacting us at support@ProVPN.com.
                </p>
                <p className="b-text" data-aos="fade-up">
                  Changes to the Privacy Policy: We may change our Privacy
                  Policy from time to time, without prior notice to you,
                  consistent with applicable privacy laws and principles. Your
                  continued use of the Site or Services constitutes your
                  acceptance of our Privacy Policy.
                </p>
                <p className="b-text" data-aos="fade-up">
                  How to Contact ProVPN: If you have any questions regarding our
                  Privacy Policy and how we handle your information, please feel
                  free to contact ProVPN at the following email address:
                  support@ProVPN.com.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterPages
        mt0={true}
        pt100={true}
        text="ProVPN is committed to providing the best VPN service available. Try our 7-day free trial today and experience the difference. Your privacy is our priority."
      />
    </>
  );
};

export default PrivacyContainer;
