import {
    SecPartnersPartners1,
    SecPartnersPartners2,
    SecPartnersPartners3,
    SecPartnersPartners4,
    SecPartnersPartners5,

} from '../../utils/allImgs'

const SecParttnsInfo = [
    {
        img:SecPartnersPartners1
    },
    {
        img:SecPartnersPartners2
    },
    {
        img:SecPartnersPartners3
    },
    {
        img:SecPartnersPartners4
    },
    {
        img:SecPartnersPartners5
    },
    {
        img:SecPartnersPartners1
    }

]

export default SecParttnsInfo