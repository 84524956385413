import {
    OurServiceIconsS1,
    OurServiceIconsS2,
    OurServiceIconsS3,
    OurServiceIconsS4,
} from '../../utils/allImgs'

const OurServiceContent = [
    {
        img: OurServiceIconsS1,
        title: "Secure Internet Browsing",
        text: "Protect your online activity with top-tier encryption. Browse the web securely, keeping your personal data private from hackers and trackers."
    },
    {
        img: OurServiceIconsS2,
        title: "Global Server Access",
        text: "Connect to any of our high-speed servers worldwide. Access content from any region and enjoy a fast, stable connection wherever you are."
    },
    {
        img: OurServiceIconsS3,
        title: "Fastest VPN Services",
        text: "Experience lightning-fast VPN speeds with unlimited bandwidth. Stream, game, and browse without interruption, no matter where you are."
    },
    {
        img: OurServiceIconsS4,
        title: "7-Day Free Trial",
        text: "Try ProVPN free for 7 days with full access to all features. No commitment, cancel anytime if you’re not satisfied."
    }
]

export default OurServiceContent;
