import {
    WelcomeAreaIcons1,
    WelcomeAreaIcons2,
    WelcomeAreaIcons3,
} from '../../utils/allImgs'

const WelcomeAreaInfo = [
    {
        img: WelcomeAreaIcons1,
        history: "120,000+ IPs",
        text: "Access global IPs to protect your privacy and bypass restrictions."
    },
    {
        img: WelcomeAreaIcons2,
        history: "24/7 Support",
        text: "We're here to help anytime, day or night."
    },
    {
        img: WelcomeAreaIcons3,
        history: "Stable Connections",
        text: "Reliable VPN for streaming, gaming, and browsing."
    },
]

export default WelcomeAreaInfo;
