const SectionHeading = ({addHome=false , title , ClassTextDIV="" , ClassTextH2="" , ClassTextP="" , text , textTop , textDown}) => {

  return (
  	<>
		<div className="section-heading text-center">
		<div className={ClassTextDIV} data-aos="fade-up">{title}</div>
		<h2 className={ClassTextH2} data-aos="fade-up" data-aos-delay='300'>{textTop}</h2>
		<p className={ClassTextP} data-aos="fade-up" data-aos-delay='400'>{textDown}</p>
		</div>
    </>
  );
}

export default SectionHeading;