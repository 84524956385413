import {
	OurFeaturesIcons1,
	OurFeaturesIcons2,
	OurFeaturesIcons3,
} from "../../utils/allImgs"

const OurFeaturesInfo = [
	{
		addMB: false,
		img: OurFeaturesIcons1,
		title: "Global Server Network",
		text: "Connect to high-speed servers in multiple countries, ensuring fast and secure access to the content you need, wherever you are."
	},
	{
		addMB: false,
		img: OurFeaturesIcons2,
		title: "Top-Notch VPN Security",
		text: "Enjoy military-grade encryption, no-log policy, and secure protocols to protect your online privacy from hackers and trackers."
	},
	{
		addMB: true,
		img: OurFeaturesIcons3,
		title: "Flexible Pricing Plans",
		text: "Choose from a variety of affordable pricing packages tailored to your needs, with a 7-day free trial to start risk-free."
	}
]

export default OurFeaturesInfo